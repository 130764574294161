<template>
  <a-result sub-title="分享链接已失效">
    <img :src="pic404" slot="icon" style="max-width: 80vw; max-height: 380px" />
    <a-button
      slot="extra"
      type="primary"
      style="width: 150px"
      :loading="loading"
      @click="home()"
    >
      <i
        v-if="!loading"
        class="ndl-icon-monitor ndl-margin-right-sm"
        style="position: relative; top: 1px"
      ></i>
      回到工作台
    </a-button>
  </a-result>
</template>

<script>
import pic404 from "@/assets/images/ai_no_task.svg";

export default {
  data() {
    return { pic404, loading: false };
  },
  methods: {
    home() {
      this.$router.push("/");
      this.loading = true;
    }
  }
};
</script>
